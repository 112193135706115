import Error from "./components/Error";
import Success from "./components/Success";

function MessageCard(props) {
  return (
    <section className="relative h-screen w-screen">
      {props.type === "error" && <Error {...props} />}
      {props.type === "success" && <Success {...props} />}
    </section>
  );
}

export default MessageCard;
