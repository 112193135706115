import React from "react";

function MessageCardError(props) {
  const [countdown, setCountdown] = React.useState(
    props.autoReloadAfter / 1000,
  );

  React.useEffect(() => {
    let timeout: number | undefined;
    if (props.autoReloadAfter) {
      timeout = setTimeout(() => {
        window.location.reload();
      }, props.autoReloadAfter);

      return () => clearTimeout(timeout);
    }
  }, [props.autoReloadAfter]);

  React.useEffect(() => {
    let interval: number | undefined;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  return (
    <main className="message-card">
      <div className="error-box shadow-xl">
        <div className="dot" />
        <div className="dot two" />
        <div className="face2">
          <div className="eye" />
          <div className="eye right" />
          <div className="mouth sad" />
        </div>
        <div className="move shadow" />
        <div className="message mt-2">
          <h1 className="px-5 font-bold text-white">{props.title}</h1>
          <h2 className="px-5 text-white text-xs">{props.description}</h2>
        </div>
        <a
          className="button-box btn rounded-full btn-sm h-auto"
          href={props.toDoHref}
        >
          <span>{props.toDo}</span>
        </a>
        {props.autoReloadAfter && (
          <span className="absolute -bottom-5 left-1/2 z-10 -translate-x-1/2 text-xs">
            ({countdown}) seconds
          </span>
        )}
      </div>
    </main>
  );
}

export default MessageCardError;
