function MessageCardError(props) {
  return (
    <main className="message-card">
      <div className="success-box shadow-xl">
        <div className="dot" />
        <div className="dot two" />
        <div className="face2">
          <div className="eye" />
          <div className="eye right" />
          <div className="mouth happy" />
        </div>
        <div className="move shadow" />
        <div className="message mt-2">
          <h1 className="px-5 font-bold text-white">{props.title}</h1>
          <h2 className="px-5 text-white text-xs">{props.description}</h2>
        </div>
        <a
          className="text-danger button-box rounded-full btn-sm btn"
          href={props.toDoHref}
        >
          {props.toDo}
        </a>
      </div>
    </main>
  );
}

export default MessageCardError;
